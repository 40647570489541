export const program_list = [
    'Industrial Training',
    'Internship',
    // 'Online Industrial Training',
]




export const Industrial_training = [
'Advance Java (J2EE)',
'Advance PHP(Framework CI & Laravel)',
'Advance Python(DJango Framework)',
'Android (Mobile Application Development)',
'AngularNode.js',
'Artifical Intelligence With Computer Vision',
'BigData Analysis Hadoop',
'C & C++ & Data Structure',
'Core Java (J2SE)',
'Core Python',
'Cyber Security (Ethical Hacking)',
'Data Science',
'IONIC Mobile Application Development',
'IOT(Internet OF Things)',
'Java (J2SE + J2EE)',
'Machine Learning',
'Network Administration',
'Node Js & Angular & IONIC Mobile Application Development',
'PHP (Core + Advanced): "PHP (Core + Advanced)',
"Python (Core + Advanced)",
 "Saleforce (Developer)",
"Salesforce (Admin + Developer)",
"Salesforce (Admin)",
"Software Testing (Automation)",
"Software Testing (Manual & Automation)",
"Software Testing (Manual)",
"Web Designing & Development (Core PHP)",
"iOS(Apple iPhone Development)"
]





export const internship = [
"Android(Mobile App Development)",
"Angular JS With IONIC (MEAN Stack)",
"Artificial Intelligence with Computer Vision",
"BigData Analysis with Power BI",
"Cyber Security with VAPT, CISA, CISSP",
"Data Science with Python/R/Spark",
"IOT(Internet of Things) with Industrial Applications",
"Java Technologies (J2SE and J2EE)",
"Machine Learning With NLP",
"PHP With Codeigniter & Laraval",
"Python with Django",
"React JS (MERN Stack)",
"Salesforce CRM(Admin and Developer)",
"Software Testing( Manual & Automation)",
"iOS(Apple iPhone Development)"
]


// Advance Java (J2EE): "Advance Java (J2EE)"
// Advance PHP(Framework CI & Laravel): "Advance PHP(Framework CI & Laravel)"
// Advance Python(DJango Framework): "Advance Python(DJango Framework)"
// Android (Mobile Application Development): "Android (Mobile Application Development)"
// AngularNode.js: "AngularNode.js"
// Artifical Intelligence With Computer Vision: "Artifical Intelligence With Computer Vision"
// BigData Analysis Hadoop: "BigData Analysis Hadoop"
// "C & C++ & Data Structure ": "C & C++ & Data Structure "
// Core Java (J2SE): "Core Java (J2SE)"
// Core Python: "Core Python"
// Cyber Security (Ethical Hacking): "Cyber Security (Ethical Hacking)"
// Data Science: "Data Science"
// IONIC Mobile Application Development: "IONIC Mobile Application Development"
// IOT(Internet OF Things): "IOT(Internet OF Things)"
// Java (J2SE + J2EE): "Java (J2SE + J2EE)"
// Machine Learning: "Machine Learning"
// Network Administration: "Network Administration"
// Node Js & Angular & IONIC Mobile Application Development: "Node Js & Angular & IONIC Mobile Application Development"
// PHP (Core + Advanced): "PHP (Core + Advanced)"
// Python (Core + Advanced): "Python (Core + Advanced)"
// Saleforce (Developer): "Saleforce (Developer)"
// Salesforce (Admin + Developer): "Salesforce (Admin + Developer)"
// Salesforce (Admin): "Salesforce (Admin)"
// Software Testing (Automation): "Software Testing (Automation)"
// Software Testing (Manual & Automation): "Software Testing (Manual & Automation)"
// Software Testing (Manual): "Software Testing (Manual)"
// Web Designing & Development (Core PHP): "Web Designing & Development (Core PHP)"
// iOS(Apple iPhone Development): "iOS(Apple iPhone Development)"




// Android(Mobile App Development): "Android(Mobile App Development)"
// Angular JS With IONIC (MEAN Stack): "Angular JS With IONIC (MEAN Stack)"
// Artificial Intelligence with Computer Vision: "Artificial Intelligence with Computer Vision"
// BigData Analysis with Power BI: "BigData Analysis with Power BI"
// Cyber Security with VAPT, CISA, CISSP: "Cyber Security with VAPT, CISA, CISSP"
// Data Science with Python/R/Spark: "Data Science with Python/R/Spark"
// IOT(Internet of Things) with Industrial Applications: "IOT(Internet of Things) with Industrial Applications"
// Java Technologies (J2SE and J2EE): "Java Technologies (J2SE and J2EE)"
// Machine Learning With NLP: "Machine Learning With NLP"
// PHP With Codeigniter & Laraval: "PHP With Codeigniter & Laraval"
// Python with Django: "Python with Django"
// React JS (MERN Stack): "React JS (MERN Stack)"
// Salesforce CRM(Admin and Developer): "Salesforce CRM(Admin and Developer)"
// Software Testing( Manual & Automation): "Software Testing( Manual & Automation)"
// iOS(Apple iPhone Development): "iOS(Apple iPhone Development)"