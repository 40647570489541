
import React , {useState} from 'react'
import { HeadProvider, Title, Link, Meta } from 'react-head';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';


import '../Styles/Home.module.css'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';



import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';




import {  internship , Industrial_training , program_list} from '../Data'
import { formatRelativeWithOptions } from 'date-fns/fp';
import { set } from 'date-fns';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      marginTop: 1
    },
    '& .MuiOutlinedInput-inputMultiline'  : {
      padding: 0,
      color: 'green',fontWeight:"bold"
  },
    marginTop:20
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  main : {
    padding: 1,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title  : {
    margin: 0,
    lineHeight: 1.15,
    fontSize: '2rem'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root3: {
    // minWidth: 260,
    marginLeft:10
  },
  bullet3: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title3: {
    fontSize: 14,
    color:"black",
    fontWeight:"bold"
  },
  pos3: {
    marginBottom: 12,
  },
  multilineColor:{
    color:'green'
}

}));


const App = () => {
  
  
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const [prog, setProg] = React.useState('');
  const handleChangeProg = (event) => {
      setProg(event.target.value);
      setsFlag(true)
  };


  const [sFalg, setsFlag] = React.useState(false);



  const [tech, setTech] = React.useState('');
  const handleChangeTech = (event) => {
    setTech(event.target.value);
  };



  const [selectedDate, setSelectedDate] = React.useState(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
  const handleDateChange = (date) => {
    // console.log(date)
    var newd = moment(date).format('YYYY-MM-DD HH:mm:ss');
    // console.log(newd)
    setSelectedDate(newd);
  };




  const [checked, setChecked] = React.useState({
    cflagA : true, 
    cflagB : false, 
    cflagC : false, 
  });
  const handleChangeCheck = (event) => {
    // console.log(event.target.name)
    if(event.target.name === 'cflagA')
    {
      setChecked({['cflagA']: true , ['cflagB']: false , ['cflagC']: false});
      setAmount(1000)
    }
    else if(event.target.name === 'cflagB')
    {
      setChecked({['cflagA']: false , ['cflagB']: true , ['cflagC']: false});
      setAmount(14000)

    }
    else if(event.target.name === 'cflagC')
    {
      setChecked({['cflagA']: false , ['cflagB']: false , ['cflagC']: true});
      setAmount(15000)

    }
  };




  const [amount , setAmount] = React.useState(1000)


  const [tc , setTc] = React.useState({
    tcFlag : false
  })
  const handleTc = (event) =>{

    if(tc.tcFlag === false)
    {
      setTc({['tcFlag']  : true })

    }
    else
    {
      setTc({['tcFlag'] : false})
    }


  }



  const [values , setValues]  = useState({
    // name : 'bhanu',
    // mobile :  '9549339982',
    // fathername   : "Ganpat Singh",
    // email  :'bhanu@gmail.com',
    // qualification : 'B.Tech',
    // college_name:'',

    name : '',
    mobile :  '',
    fathername   : "",
    email  :'',
    qualification : '',
    college_name:'',
})











const handleChange = (event) =>
{
  setValues({
    ...values,
    [event.target.name]: event.target.value,
  });
}


    function isDate(val) {
        return Object.prototype.toString.call(val) === '[object Date]'
      }

    function isObj(val) {
        return typeof val === 'object'
      }
      
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
          return JSON.stringify(val)
        } else {
          return val
        }
      }
      
    function buildForm({ action, params }) {
          const form = document.createElement('form')
          form.setAttribute('method', 'post')
          form.setAttribute('action', action)
        
          Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
          })
        
          return form
      }
      
    function post(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
      }
    
const getData=(data)=>
       {
        //  console.log("yaha")
        // return fetch(`http://localhost:3095/api/payment`,{
        return fetch(`http://139.162.53.166:3095/api/payment`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(data)
        }).then(response=>response.json()).catch(err=>console.log(err))
}



const notify = (msg , type) => {

  if(type === 'success')
  {
    var toastId = null;
    var customToastId = 1234;
    if (toast.isActive(toastId)) {
      toastId = toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  
    toast.success(msg, {
      toastId: customToastId,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }
  else if (type === 'error')
  {
    var toastId = null;
    var customToastId = 1234;
    if (toast.isActive(toastId)) {
      toastId = toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        
      });
    }
  
    toast.error(msg, {
      toastId: customToastId,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }    
  else if (type === 'warn')
  {
    var toastId = null;
    var customToastId = 1234;
    if (toast.isActive(toastId)) {
      toastId = toast.warn(msg, {
          position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  
    toast.warn(msg, {
      toastId: customToastId,
      position: toast.POSITION.TOP_RIGHT,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }
    };



const makePayment=()=>{
          var phoneno = /^[6-9]\d{9}$/
          var emailx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          if(values.name  === "" || values.name === undefined || values.name === null)
          {
              notify("Please Enter Your Name" , 'error')
          }
          else if(values.name.length < 2)
          {
            notify("Enter  A Valid Name",'error')
          }
          else if(values.fathername  === "" || values.fathername === undefined || values.fathername === null)
          {
            notify("Please Enter Your Father Name" , 'error')
          }
          else if(values.fathername.length < 2)
          {
            notify("Enter  A Valid Name",'error')
          }
          else if(!values.mobile.match(phoneno)  )
          {
            notify("Enter  A Valid Mobile Number",'error')
          }
          else if(!values.email.match(emailx)  )
          {
            notify("Enter  A Valid Email",'error')
          }
          else if(values.qualification  === "" || values.qualification === undefined || values.qualification === null)
          {
            notify("Please Enter Your Qualification" , 'error')
          }
          else if(values.qualification.length < 2)
          {
            notify("Enter  A Valid Qualification",'error')
          }
          else if(values.college_name  === "" || values.college_name === undefined || values.college_name === null)
          {
            notify("Please Enter Your College Name" , 'error')
          }
          else if(values.college_name.length < 2)
          {
            notify("Enter  A Valid College Name",'error')
          }
          else if(prog  === "" || prog === undefined || prog === null)
          {
            notify("Please Select Program Name" , 'error')
          }
          else if(prog.length < 2)
          {
            notify("Select A Valid Program Name",'error')
          }
          else if(tech  === "" || tech === undefined || tech === null)
          {
            notify("Please Select Technology" , 'error')
          }
          else if(tech.length < 2)
          {
            notify("Select A Valid Technology",'error')
          }
          else if(tc.tcFlag === false)
          {
            notify("Accept Terms & Conditions",'error')
          }
          else
          {

            var tempData = {
              'name' : values.name,
              'father_name' : values.fathername,
              'email' : values.email,
              'mobile' : values.mobile,
              'qualification' : values.qualification,
              'program' : prog,
              'technology' : tech,
              'college_name'  : values.college_name,
              'joining_date' : selectedDate,
              'amount' : amount
  
            }
            // console.log(tempData)
          }



        getData(tempData).then(response=>{
        var information={
        // action:"https://securegw-stage.paytm.in/order/process",
        action:"https://securegw.paytm.in/order/process",
        params:response
        }
        post(information)
        // console.log(information)
        })
    }

  







    return (

<form className={classes.root} noValidate autoComplete="off">
  
<ToastContainer />
{/* <button onClick={()=>notify("this is test " , 'success')}>Notify !</button> */}


      <div className={classes.root}>
     

      <main className={classes.main}>
        <h1 className={classes.title}>
          Netparam Technologies Pvt. Ltd.
          <p></p>
          {/* <a href="https://nextjs.org">Next.js!</a> */}
        </h1>
        </main>

      <Grid container spacing={3}>
        <Grid item xs={2}>
          {/* <Paper className={classes.paper}>xs=6</Paper> */}
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
          <TextField
          required
          id="outlined-required"
          label="Your Name"

          name='name'
          onChange={handleChange}
          value={values.name}
          variant="outlined"
          style={{width:"100%"}}
        />
         <TextField
          required
          id="outlined-required"
          label="Father Name"
          onChange={handleChange}
          name="fathername"
          value={values.fathername}
          variant="outlined"
          style={{width:"100%"}}
        />
         <TextField
          required
          id="outlined-required"
          onChange={handleChange}
          label="Your Email"
          name='email'
          value={values.email}
          variant="outlined"
          style={{width:"100%"}}
        />
         <TextField
          required
          id="outlined-required"
          label="Mobile Number"
          onChange={handleChange}
          name='mobile'
          value={values.mobile}
          variant="outlined"
          style={{width:"100%"}}
        />
         <TextField
          required
          id="outlined-required"
          label="Qualification"
          onChange={handleChange}
          name='qualification'
          value={values.qualification}
          variant="outlined"
          style={{width:"100%"}}
        />

        <FormControl style={{width:"100%"}} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Program</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={prog}
          onChange={handleChangeProg}
          label="Program"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {program_list.map((data , i) =>
          <MenuItem key={i} value={data}>{data}</MenuItem>
          )}

        </Select>
      </FormControl>

{sFalg === true && prog === "Industrial Training" ?

<>
      <FormControl style={{width:"100%"}} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Technology</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={tech}
          onChange={handleChangeTech}
          label="Technology"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Industrial_training.map((data , i) =>
          <MenuItem key={i} value={data}>{data}</MenuItem>
          )}

        </Select>
      </FormControl>


</>      

 : sFalg === true && prog === "Internship"  ?
 <>
   <FormControl style={{width:"100%"}} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Technology</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={tech}
          onChange={handleChangeTech}
          label="Technology"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {internship.map((data , i) =>
          <MenuItem key={i} value={data}>{data}</MenuItem>
          )}

        </Select>
      </FormControl>
 </> 
 :
 <>


 </>
}  

<TextField
          required
          id="outlined-required"
          label="College Name"
          onChange={handleChange}
          name='college_name'
          value={values.college_name}
          variant="outlined"
          style={{width:"100%"}}
        />

              
{/* 
      <p></p> */}


      <div  style={{width:"99%" , border:"1px solid gray",height:57 , borderRadius:6 , marginLeft:10 , marginRight:30}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                          fullWidth
                          margin="normal"
                          style={{ border: "1px  black" }}
                          id="date-picker-dialog"
                          label="Joining Date "
                          format="MM/dd/yyyy"
                          value={selectedDate}
                          variant="outlined"
                          onChange={handleDateChange}
                          variant="outlined"
                          minDate={Date.now()}
                          style={{width:"98%" , borderWidth:10, borderColor:"black" ,marginTop:10 , borderBottom:"1px solid white"}}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      
                      </MuiPickersUtilsProvider>

      </div>

<br></br>
<Card className={classes.root3} variant="outlined">
      <CardContent>
        <Typography className={classes.title3} color="textSecondary" gutterBottom>
          Select Payment Type
        </Typography>
        {/* <Typography variant="h5" component="h2">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography className={classes.pos3} color="textSecondary">
          adjective
        </Typography>
        <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      <Grid container spacing={3}>
        <Grid item xs={4} sm={4}>
          <Paper className={classes.paper}>
          <FormControlLabel
        control={
          <Checkbox
            checked={checked.cflagA}
            onChange={handleChangeCheck}
            name="cflagA"
            color="primary"
          />
        }
        label={<Typography style={{fontSize:12 , color:"black"  ,fontWeight:"bold" }} gutterBottom>
        Registration Fee
      </Typography>}
      />
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Paper className={classes.paper}>
          <FormControlLabel
        control={
          <Checkbox
            checked={checked.cflagB}
            onChange={handleChangeCheck}
            name="cflagB"
            color="primary"
          />
        }
        
        label={<Typography style={{fontSize:12 , color:"black"  ,fontWeight:"bold" }}  gutterBottom>
        Student Development Fee
      </Typography>}
      />
          </Paper>
        </Grid>



        <Grid item xs={4} sm={4}>
          <Paper className={classes.paper}>
          <FormControlLabel
        control={
          <Checkbox
            checked={checked.cflagC}
            onChange={handleChangeCheck}
            name="cflagC"
            color="primary"
          />
        }
        label={<Typography style={{fontSize:12 , color:"black"  ,fontWeight:"bold" }}  gutterBottom>
        Both
      </Typography>}
      />
          </Paper>
        </Grid>
      </Grid>
      <br></br>
      <TextField
          required
          // label="Father Name"
          onChange={handleChange}
          multiline
          name="fathername"
          value={`Total Amount     :     ₹  ${amount}`}
          variant="outlined"
          style={{width:"98%"}}
          disabled={true}
        />

<br></br>

<Grid container spacing={3} >
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper }>
          <FormControlLabel
          style={{justifyContent:"left"}}
        control={
          <Checkbox
            checked={tc.tcFlag}
            style={{justifyContent:"left" }}
            onChange={handleTc}
            name="Registration Fee"
            color="primary"
          />
        }
        label={<Typography style={{fontSize:12 , color:"black"  ,fontWeight:"bold" }}>
        I have read and accept Terms and Conditions
      </Typography>}
      />
<br></br>
<br></br>
<Button onClick={makePayment} style={{width:"100%"}} variant="contained" color="primary">
       Pay Now
      </Button>
          </Paper>
        </Grid>
        </Grid>


   
     
      
    </Card>





          </Paper>



        </Grid>
        
        <Grid item xs={2}>
          {/* <Paper className={classes.paper}>xs=6</Paper> */}
        </Grid>
      </Grid>

    

    </div>
    </form>
      
    )
}

export default App
