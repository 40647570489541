import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import styles from '../Styles/Home.module.css'

  import { Wave, Random } from 'react-animated-text';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      justifyContent:"center"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const  Home = () => {
    const classes = useStyles();

    return(
      
    



         
     <>     
      <div className={classes.root}>
      <Random
      text="Netparam Technologies"
      effect="jump"
      effectChange={0.5}
      effectDuration={5.0}
      style={{fontSize:'4rem'}}
    />
      <Typography variant="h3" style={{textAlign:"center" , fontWeight:"bold" , marginTop:100}} component="h2" gutterBottom>
        Netparam Technologies
      </Typography>

      <br></br>
      <br></br>
      <br></br>
      <Grid container spacing={3}>

        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
        <Link to="/register">
        <Paper className={styles.card}>

        <Typography variant="h5" style={{textAlign:"center" , fontWeight:"bold" }} component="h2" gutterBottom>
        I want to Register
      </Typography>
        </Paper>
        </Link>
         
        </Grid>

        <Grid item xs={4}>
        <Link to="/payment">
        <Paper className={styles.card}>

        <Typography variant="h5" style={{textAlign:"center" , fontWeight:"bold" }} component="h2" gutterBottom>
        I want to Pay Fee
      </Typography>

        </Paper>
        </Link>

        </Grid>
        <Grid item xs={2}></Grid>



      </Grid>
    </div>
          




   
      </>

    )
  }


  export default Home;

  
  